import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoubleTapDirective } from './double-tap.directive';
import { SwipeDirective } from './swipe.directive';



@NgModule({
  declarations: [
    DoubleTapDirective,
    SwipeDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SwipeDirective,
    DoubleTapDirective
  ]
})
export class DirectivesModule { }
